<template>
  <main>
    <CForm>
      <CCard>
        <CAlert id="ChooseNotice" v-if="(ChooseSlugs.length > 0)" color="info" :fade="false">
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseSlugs.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseSlugs = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton v-if="$store.state.user.permission.Permission.Post.Items.includes('D')" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Content/FAQ') }}</h5>
            </CCol>
            <CCol col="6" class="d-flex justify-content-end">
              <div v-if="Category" class="card-header-actions d-flex">
                <CButton color="success" variant="outline" shape="pill" class="mr-2" type="button" @click="$router.push('/content/faq-add')">
                  <CIcon size="sm" class="mr-1" name="cil-plus"/>{{ $t('Global.Add') + $t('Navigation.Content/FAQ') }}
                </CButton>
                <CButton color="success" variant="outline" shape="pill" @click="FAQAddCategoryModel = true">
                  <CIcon size="sm" class="mr-1" name="cil-plus"/> {{ $t('Global.Add') + $t('Navigation.Content/FAQ') + $t('Global.Category') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody v-if="!Category && !Loading">
          <div class="jumbotron bg-white text-center py-5">
            <h1 class="display-6 font-weight-bold mb-3">啟用「顧客問與答」</h1>
            <p class="lead mb-4">
              啟用後將會自動產生一個「顧客問與答」的文章分類，後續只需要在此介面上新增顧客問與答的內容即可。
            </p>
            <a class="btn btn-info btn-lg" href="#" role="button" @click="InitFAQ">啟用</a>
          </div>
        </CCardBody>
        <CCardBody v-else class="p-0">
          <CTabs :active-tab="CurrentTabIndex" fade addTabsWrapperClasses="tab-info border-0 p-0" addNavClasses="border-top-0 border-left-0 border-right-0" @update:activeTab="ChangeTab">
            <CTab v-for="CategoryData in CategoryList" :key="CategoryData._id" :title="CategoryData.Name">
              <CCard class="border-0">
                <CCardBody class="p-0 border-top-0">
                  <CDataTable
                    id="PostList"
                    :items="List"
                    :fields="Field"
                    :loading="Loading"
                    :noItemsView="noItemsView"
                    responsive
                    hover
                  >
                    <template #Checkbox-header>
                      <label class="mb-0">
                        <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
                      </label>
                    </template>
                    <template #Checkbox="{item}">
                      <td class="text-center">
                        <label class="mb-0">
                          <input type="checkbox" :id="'Checkbox-Post-' + item.Slug" :value="item.Slug" v-model="ChooseSlugs">
                        </label>
                      </td>
                    </template>
                    <template #Action="{item}">
                      <td>
                        <CButton v-if="$store.state.user.permission.Permission.Post.Items.includes('W')" color="info" size="sm" class="mr-1" @click="$router.push(`/content/faq/${item.Slug}`)">
                          <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/> {{ $t('Post/List.Edit') }}
                        </CButton>
                      </td>
                    </template>
                  </CDataTable>
                </CCardBody>
              </CCard>
            </CTab>
          </CTabs>
        </CCardBody>
        <CCardFooter>
          <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="ChangePage()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="FAQAddCategoryModel" class="ActionModel" :show.sync="FAQAddCategoryModel" :centered="true" :closeOnBackdrop="!Submit">
      <CInput
        :label="$t('Global.Category') + $t('Post/Detail.Name')"
        v-model="AddCategoryName"
        horizontal
      />
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Add') + $t('Navigation.Content/FAQ') + $t('Global.Category') }}</h5>
          <CButtonClose @click="FAQAddCategoryModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FAQAddCategoryModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FAQAddCategoryModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FAQAddCategoryModelSubmit" @click="AddFAQCategory()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <component :is="Component" :Toggle.sync="ToggleModal" @Success="GetList()" />
  </main>
</template>

<route>
{
  "meta": {
    "label": "顧客問與答"
  }
}
</route>

<script>
export default {
  name: 'ListPost',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data () {
    return {
      List: [],
      Category: null,
      CategoryList: [
        {
          Slug: 'ALL',
          Name: '所有內容'
        }
      ],
      AddCategoryName: '',
      AlertModel: false,
      ChooseSlugs: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Post/List.NoResults'),
        noItems: this.$t('Post/List.NoItems')
      },
      SearchFilter: {
        startTime: null,
        endTime: null,
        SelectCategories: [],
        SelectCategoriesName: '',
        categories: [],
        categoryName: [],
        tags: [],
        name: '',
        slug: [],
        status: ''
      },
      CurrentTabIndex: 0,
      FAQAddCategoryModel: false,
      Component: null,
      ToggleModal: false
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Name', label: this.$t('Post/List.Name') },
        { key: 'Slug', label: this.$t('Post/List.Slug') },
        { key: 'Action', label: '' }
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      this.Loading = true
      return this.GetCategory().then(() => {
        if (this.Category && this.Category._id) {
          this.GetList()
        }
        this.Loading = false
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      this.List = []
      this.Loading = true
      const RequestQuery = {
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order,
        categories: ['faq']
      }
      if (this.CategoryList[this.CurrentTabIndex] && this.CategoryList[this.CurrentTabIndex].Slug) {
        RequestQuery.categories.push(this.CategoryList[this.CurrentTabIndex].Slug)
      }
      RequestQuery.categories = RequestQuery.categories.join(',')
      return this.$store.dispatch('InnerRequest', {
        url: '/content/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      this.GetList()
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/delete',
        method: 'post',
        data: {
          slug: this.ChooseSlugs
        }
      }).then(() => {
        this.Init()
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Post/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Post/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseSlugs = this.List.map(data => {
          return data.Slug
        })
      } else {
        this.CheckAllValue = false
        this.ChooseSlugs = []
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.ChooseSlugs = []
    },
    GetCategory () {
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/detail',
        method: 'get',
        params: {
          slug: 'faq'
        }
      }).then(({data}) => {
        this.Category = data.detail
        this.CategoryList = [
          {
            Name: '所有內容'
          },
          ...data.categories
        ]
      }).catch((err) => {
        throw err
      })
    },
    InitFAQ() {
      const LanguageData = {}
      if (this.$store.state.user.permission.MutipleLocale) {
        this.$store.state.user.permission.Locales.forEach((locale) => {
          LanguageData[locale] = {
            Name: 'FAQ',
            Tags: [],
            SEO: {
              Description: '',
              Keywords: ''
            },
            Cover: '',
            Carousel: [],
            ResponseData: {
              Mobile: {},
              Web: {}
            }
          }
        })
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/add',
        method: 'post',
        data: {
          Name: '顧客問與答',
          Slug: 'faq',
          Tags: [],
          RelateCategories: [],
          Status: 1,
          Sort: 0,
          Cover: '',
          EnableResponseContent: 0,
          SEO: {
            Description: '',
            Keywords: ''
          },
          Carousel: [],
          LanguageData,
          ResponseData: {
            Mobile: {},
            Web: {}
          },
        }
      }).then(() => {
        this.Init()
      })
    },
    AddFAQCategory() {
      const Slug = this.AddCategoryName + '-' + this.$dayjs().unix()
      const LanguageData = {}
      if (this.Category.LanguageData) {
        Object.keys(this.Category.LanguageData).forEach((locale) => {
          LanguageData[locale] = {
            ...this.Category.LanguageData,
            Name: this.AddCategoryName
          }
        })
      }
      this.Loading = true
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/category/add',
        method: 'post',
        data: {
          ...this.Category,
          Name: this.AddCategoryName,
          Slug,
          Tags: [],
          RelateCategories: [],
          Status: 1,
          Sort: 0,
          Cover: '',
          EnableResponseContent: 0,
          SEO: {
            Description: '',
            Keywords: ''
          },
          Carousel: [],
          LanguageData,
          ResponseData: {
            Mobile: {},
            Web: {}
          },
        }
      }).then(() => {
        return this.$store.dispatch('InnerRequest', {
          url: '/content/category/edit',
          method: 'post',
          data: {
            slug: this.Category.Slug,
            updateData: {
              Slug: this.Category.Slug,
              RelateCategories: [
                ...this.Category.RelateCategories,
                Slug
              ]
            }
          }
        }).then(() => {
          this.FAQAddCategoryModel = false
          this.GetCategory()
          this.Submit = false
          this.Loading = false
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.PostCategory/AddSuccess'),
            type: 'success'
          })
        })
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangeTab(tabIndex) {
      this.CurrentTabIndex = tabIndex
      this.GetList()
    },
  }
}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#PostList thead th {
  border-top: 0;
}
#PostList thead th:first-child {
  text-align: center;
}
#PostList tbody td:first-child {
  vertical-align: middle;
}
</style>
